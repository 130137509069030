/* You can add global styles to this file, and also import other style files */

$enable-negative-margins: true;

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Bold.eot');
  src:
    url('/assets/fonts/SourceCodePro-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SourceCodePro-Bold.woff2') format('woff2'),
    url('/assets/fonts/SourceCodePro-Bold.woff') format('woff'),
    url('/assets/fonts/SourceCodePro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Digital-7';
  src: url('/assets/fonts/Digital-7.eot');
  src:
    url('/assets/fonts/Digital-7.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Digital-7.woff2') format('woff2'),
    url('/assets/fonts/Digital-7.woff') format('woff'),
    url('/assets/fonts/Digital-7.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '14-Segment-LED';
  src: url('/assets/fonts/14SegmentLED.eot');
  src:
    url('/assets/fonts/14SegmentLED.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/14SegmentLED.woff2') format('woff2'),
    url('/assets/fonts/14SegmentLED.woff') format('woff'),
    url('/assets/fonts/14SegmentLED.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Alarm-Clock';
  src: url('/assets/fonts/alarmclock.eot');
  src:
    url('/assets/fonts/alarmclock.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/alarmclock.woff2') format('woff2'),
    url('/assets/fonts/alarmclock.woff') format('woff'),
    url('/assets/fonts/alarmclock.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  font-family: 'Roboto', monospace;
}

.mw-50 {
  max-width: 50%;
}

.fs-7 {
  font-size: 0.875rem;
}

.action-btn-group,
.action-btn {
  visibility: hidden;
  display: none;
}

.list-group-item:hover {
  .action-btn-group,
  .action-btn {
    visibility: visible;
    display: block;
  }

  .list-group-item-value {
    visibility: hidden;
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Source Code Pro', monospace !important;
  font-weight: bold;
}

.form-field {
  position: relative;

  .btn {
    visibility: hidden;
  }
}

.form-field:hover {
  .btn {
    visibility: visible;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#fff 0 0);
  --m: radial-gradient(farthest-side, #fff 92%, #ffff);
  background: var(--c), var(--m), var(--c), var(--m), var(--c), var(--m);
  background-size:
    8px 15px,
    8px 8px;
  background-repeat: no-repeat;
  animation: l14 1s infinite alternate;
}
@keyframes l14 {
  0%,
  10% {
    background-position:
      0 0,
      0 100%,
      50% 0,
      50% 100%,
      100% 0,
      100% 100%;
  }
  33% {
    background-position:
      0 100%,
      0 0,
      50% 0,
      50% 100%,
      100% 0,
      100% 100%;
  }
  66% {
    background-position:
      0 100%,
      0 0,
      50% 100%,
      50% 0,
      100% 0,
      100% 100%;
  }
  90%,
  100% {
    background-position:
      0 100%,
      0 0,
      50% 100%,
      50% 0,
      100% 100%,
      100% 0;
  }
}
